<script>
export default {
  data () {
    return {
      pageTitle: '茅台新春酒'
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="header">
      <img class="header-img" src="../src/assets/xx.png" >
      <h1 class="title">{{ pageTitle }}</h1>
    </div>
    <img class="background-img" src="../src/assets/index-img.png" >
  </div>
</template>

<style scoped>
.container{
}
.header {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

/* 调整图片宽度 */
.header-img {
  width: 10px;
  height: 20px;
}

/* 响应式样式 */
@media screen and (min-width: 501px) {
  .container{
   padding:10px 40%;
  }
  .header-img {
    width: 13px;
    height: 13px;
  }
  .title{
    margin-left: 120px;
    width: 200px;
  }
  .background-img {
    width: auto; /* 防止图片沾满全屏 */
    margin: auto;
  }
  .container{
    margin: auto;
  }
}

/* 小屏幕下的字体大小 */
@media screen and (max-width: 500px) {
  .title {
    font-size: 18px;
    margin: auto;
    margin-top: 10px;
  }
  .header-img {
    margin-top: 10px;
    width: 13px;
    height: 13px;
  }
  .background-img {
    width: 100%; /* 防止图片沾满全屏 */
    height: 100%;
    margin-top: 20px;
  }

}
</style>
